import React from "react";
import { Button } from "react-bootstrap";
import video from "../../assets/video/herovideo.mp4";
import video1 from "../../assets/video/video2.mp4";

const BannerContatctUs = (props) => {
  // const navigate = useNavigation()
  return (
    <div className="banner-wrap">
      {/* <div data-aos-duration="500" data-aos="fade-up" data-aos-delay="1100"> */}
      {/* <video src={video} loop muted autoPlay className="banner-video" /> */}
      <video
        src={
          "https://all-sites-under.s3.us-east-2.amazonaws.com/geothermal/geothermal-360-movies.mp4"
        }
        loop
        muted
        autoPlay
        className="banner-video"
      />
      {/* <ReactPlayer url="https://all-sites-under.s3.us-east-2.amazonaws.com/geothermal/geothermal.mp4" /> */}
      {/* </div> */}

      {/* <div data-aos-duration="500" data-aos="fade-up" data-aos-delay="1200">
        <img src={banner} alt="" className="banner-svg" />
      </div> */}

      <div className="banner-content-contact">
        <div data-aos-duration="500" data-aos="fade-up" data-aos-delay="1300">
          <div className="row">
            <div className="nik">
              <div className=" opacity-100  banner-text-top">
                {/* <p className="opacity-100" style={{ color: "#65B0F7" }}>
                  <b>GT TITAN</b>
                </p> */}
                <h5 style={{ color: "#3792E8" }}>GT TITAN</h5>
                <p
                  className="banner-text text-white"
                  style={{
                    fontSize: "1.7rem",
                    lineHeight: "1.4",
                    fontWeight: "bold",
                  }}
                >
                  Developing Reliable Geothermal Baseload Energy for Texas, USA
                  and around the Globe.
                </p>
                <div className="pt-2 d-flex flex-row ">
                  <Button
                    onClick={props.onLearnMore}
                    className="btn learn-more  me-2 rounded-0 text-white"
                    title="Learn more"
                  >
                    Learn more
                  </Button>{" "}
                  &nbsp;
                  {/* <Button
                    onClick={props.onLets}
                    className="btn btn-outline-success transparent-bg rounded-0 text-white"
                    title="Let's connect"
                  >
                    Let's connect
                  </Button> */}
                </div>
              </div>
            </div>
            <div className=" col-6" />
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default BannerContatctUs;
